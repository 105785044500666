:root {
  --puff-collection-background-paper: url('../assets-velimark/images/paper-background-2.jpeg');
  --puff-collection-background-paper-webp: url('../assets-velimark/images/paper-background-2.webp');

  --paragraph-sticker-2: url('../assets-velimark/images/Papernote_BeHeard.png');
  --paragraph-sticker-2-webp: url('../assets-velimark/images/Papernote_BeHeard.webp');

  --paragraph-sticker-3: url('../assets-velimark/images/Papernote_KeepGrowing.png');
  --paragraph-sticker-3-webp: url('../assets-velimark/images/Papernote_KeepGrowing.webp');

  --paragraph-sticker-4: url('../assets-velimark/images/Papernote_beige.jpg');
  --paragraph-sticker-4-webp: url('../assets-velimark/images/Papernote_beige.webp');
}
