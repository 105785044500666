cx-page-layout.LandingPageTemplate {
  .StartCategoryPuffs {
    @include media-breakpoint-down(sm) {
      .btn-ternary {
        width: 100% !important;
        font-size: 18px !important;
      }

      py-paragraph * {
        text-align: left !important;
      }
    }
  }
}
