a,
.link,
.btn.btn-link {
  color: var(--py-color-button-primary) !important;

  &:hover {
    color: var(--py-color-button-primary) !important;
  }

  &:disabled {
    color: var(--py-color-grey-dark) !important;
  }

  &:visited {
    color: var(--py-color-button-primary) !important;
  }

  &:focus-visible {
    &:link,
    &:visited {
      outline: 1px auto var(--py-color-button-primary--60) !important;
    }
  }
}
