@import 'declarations';

cx-navigation {
  .navigation-header {
    font-size: 18px !important;
    color: var(--py-color-grey-200) !important;
    margin-bottom: spacer(3) !important;
  }

  .navigation-body,
  .navigation-body-horizontal:not(.certificate-link) {
    .has-content {
      padding-bottom: 16px;
    }
  }

  .navigation-body-horizontal.certificate-link {
    .link-item-wrapper {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    .navigation-body-horizontal {
      .has-content {
        padding-bottom: 0;
      }
    }
  }

  .navigation-body,
  .navigation-body-horizontal {
    .no-link {
      color: var(--py-color-white) !important;
    }

    a.open-consent-link,
    cx-generic-link a {
      display: flex;
      color: var(--py-color-primary-300) !important;

      &:focus,
      &:visited,
      &:active {
        color: var(--py-color-primary-300) !important;
      }

      &:hover {
        color: var(--py-color-white) !important;

        i {
          color: var(--py-color-white) !important;
        }
      }
    }
  }
}
