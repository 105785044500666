$py-brand-colors: (
  // Primary
  'primary-900': #2d1505,
  'primary-800': #5a2a0a,
  'primary-700': #863f0f,
  'primary-600': #b35414,
  'primary-500': #e06919,
  'primary-400': #e68747,
  'primary-300': #eca575,
  'primary-200': #f7d7bf,
  'primary-100': #fcf0e8,

  // Secondary
  'secondary-900': #000c33,
  'secondary-800': #001865,
  'secondary-700': #002598,
  'secondary-600': #0031ca,
  'secondary-500': #003dfd,
  'secondary-400': #3364fd,
  'secondary-300': #668bfe,
  'secondary-200': #b3c5fe,
  'secondary-100': #eaf6f9,

  // Button colors
  'button-primary': #141415,
  'button-secondary': #e06919,
  'button-danger': #f43f3f,
  'button-locked': #e5e5e6,

  // Global
  'global-selected': #fde6ce,
  'global-hover': #ffd09e,
  'global-active': #f78f1e,
  'global-active-hover': #945612,
  'global-tooltip': #646568,

  // Deprecated colors from legacy designs - DO NOT USE IN NEWLY DEVELOPED CODE
  // TODO ESVCX-5948 - remove legacy colors
  // Deprecated colors START
  'primary-dark': #b35414,
  'primary': #e06919,
  'primary-light': #f7d7bf,
  'secondary-darker': #000c33,
  'secondary-dark': #002598,
  'secondary': #003dfd,
  'secondary-light': #668bfe,
  'success': #003dfd,
  'success-secondary': #000c33,
  // Deprecated colors END
) !default;

:root {
  @include generate-py-variables($py-brand-colors);
}
