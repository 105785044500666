$button-shadow-shape: 0 2px 4px;
$button-shadow-shape-large: 0 4px 8px;

.btn {
  &-primary {
    // TODO ESVCX-5948 - update definition
    @include button-colors(
      var(--py-color-white),
      var(--py-color-button-primary),
      var(--py-color-button-primary),
      var(--py-color-button-primary),
      var(--py-color-grey-600),
      var(--py-color-button-primary),
      var(--py-color-grey-600),
      var(--py-color-button-primary),
      var(--py-color-grey-600),
      $button-shadow-shape rgba(20, 20, 21, 0.2),
      $button-shadow-shape-large rgba(20, 20, 21, 0.4)
    );
  }

  &-secondary {
    // TODO ESVCX-5948 - update definition
    @include button-colors(
      var(--py-color-white),
      var(--py-color-button-secondary),
      var(--py-color-button-secondary),
      var(--py-color-button-secondary),
      var(--py-color-primary-800),
      var(--py-color-button-secondary),
      var(--py-color-primary-800),
      var(--py-color-button-secondary),
      var(--py-color-primary-800),
      $button-shadow-shape rgba(224, 105, 25, 0.2),
      $button-shadow-shape-large rgba(224, 105, 25, 0.4)
    );
  }

  &-plain-primary {
    @include button-outline-colors(
      var(--py-color-button-primary),
      var(--py-color-button-primary),
      var(--py-color-white),
      var(--py-color-line-soft),
      var(--py-color-global-active)
    );

    box-shadow: none !important;
  }

  &-plain-secondary {
    @include button-outline-colors(
      var(--py-color-black),
      var(--py-color-black),
      var(--py-color-white),
      var(--py-color-line-soft),
      var(--py-color-global-active)
    );

    box-shadow: none !important;
  }

  &-plain-danger {
    @include button-outline-colors(
      var(--py-color-button-danger),
      var(--py-color-button-danger),
      var(--py-color-white),
      var(--py-color-line-soft),
      var(--py-color-global-active)
    );

    box-shadow: none !important;
  }

  &-ternary {
    // TODO ESVCX-5948 - remove
    @include button-colors(
      var(--py-color-black),
      var(--py-color-white),
      var(--py-color-grey-light),
      var(--py-color-white),
      var(--py-color-primary),
      var(--py-color-white),
      var(--py-color-primary),
      var(--py-color-grey-lighter),
      var(--py-color-primary)
    );

    box-shadow: none !important;

    &:not(:active):not(:focus):not(:hover):not(:disabled) {
      border-width: 1px;
      &:not(.btn-icon) {
        padding: calc(#{$padding-y} + 1px) calc(#{$padding-x} + 1px); //increasing padding to compensate for smaller border
      }
      &.btn-icon {
        padding: calc(#{$icon-padding} + 1px); //increasing padding to compensate for smaller border
      }
    }

    &:disabled {
      border: 2px solid var(--py-color-grey-light);
    }
  }
}
