py-detailed-stock-info {
  .basic-info-wrapper,
  .extended-stock-info-button {
    span.link-text {
      color: var(--py-color-button-primary) !important;
    }

    i {
      color: var(--py-color-button-primary) !important;
    }

    &:hover {
      text-decoration-color: var(--py-color-button-primary) !important;
    }
  }
}
