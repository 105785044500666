@import 'declarations';

py-back-to-top {
  > div {
    background-color: var(--py-color-primary-500) !important;

    &:hover {
      background-color: var(--py-color-primary-700) !important;
    }
  }
}
