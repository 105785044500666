/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
:root {
  --puff-collection-background-paper: url("../assets-velimark/images/paper-background-2.jpeg");
  --puff-collection-background-paper-webp: url("../assets-velimark/images/paper-background-2.webp");
  --paragraph-sticker-2: url("../assets-velimark/images/Papernote_BeHeard.png");
  --paragraph-sticker-2-webp: url("../assets-velimark/images/Papernote_BeHeard.webp");
  --paragraph-sticker-3: url("../assets-velimark/images/Papernote_KeepGrowing.png");
  --paragraph-sticker-3-webp: url("../assets-velimark/images/Papernote_KeepGrowing.webp");
  --paragraph-sticker-4: url("../assets-velimark/images/Papernote_beige.jpg");
  --paragraph-sticker-4-webp: url("../assets-velimark/images/Papernote_beige.webp");
}

:root {
  --py-color-primary-900--10: rgba(45, 21, 5, 0.1);
  --py-color-primary-900--20: rgba(45, 21, 5, 0.2);
  --py-color-primary-900--30: rgba(45, 21, 5, 0.3);
  --py-color-primary-900--40: rgba(45, 21, 5, 0.4);
  --py-color-primary-900--50: rgba(45, 21, 5, 0.5);
  --py-color-primary-900--60: rgba(45, 21, 5, 0.6);
  --py-color-primary-900--70: rgba(45, 21, 5, 0.7);
  --py-color-primary-900--80: rgba(45, 21, 5, 0.8);
  --py-color-primary-900--90: rgba(45, 21, 5, 0.9);
  --py-color-primary-900: #2d1505;
  --py-color-primary-800--10: rgba(90, 42, 10, 0.1);
  --py-color-primary-800--20: rgba(90, 42, 10, 0.2);
  --py-color-primary-800--30: rgba(90, 42, 10, 0.3);
  --py-color-primary-800--40: rgba(90, 42, 10, 0.4);
  --py-color-primary-800--50: rgba(90, 42, 10, 0.5);
  --py-color-primary-800--60: rgba(90, 42, 10, 0.6);
  --py-color-primary-800--70: rgba(90, 42, 10, 0.7);
  --py-color-primary-800--80: rgba(90, 42, 10, 0.8);
  --py-color-primary-800--90: rgba(90, 42, 10, 0.9);
  --py-color-primary-800: #5a2a0a;
  --py-color-primary-700--10: rgba(134, 63, 15, 0.1);
  --py-color-primary-700--20: rgba(134, 63, 15, 0.2);
  --py-color-primary-700--30: rgba(134, 63, 15, 0.3);
  --py-color-primary-700--40: rgba(134, 63, 15, 0.4);
  --py-color-primary-700--50: rgba(134, 63, 15, 0.5);
  --py-color-primary-700--60: rgba(134, 63, 15, 0.6);
  --py-color-primary-700--70: rgba(134, 63, 15, 0.7);
  --py-color-primary-700--80: rgba(134, 63, 15, 0.8);
  --py-color-primary-700--90: rgba(134, 63, 15, 0.9);
  --py-color-primary-700: #863f0f;
  --py-color-primary-600--10: rgba(179, 84, 20, 0.1);
  --py-color-primary-600--20: rgba(179, 84, 20, 0.2);
  --py-color-primary-600--30: rgba(179, 84, 20, 0.3);
  --py-color-primary-600--40: rgba(179, 84, 20, 0.4);
  --py-color-primary-600--50: rgba(179, 84, 20, 0.5);
  --py-color-primary-600--60: rgba(179, 84, 20, 0.6);
  --py-color-primary-600--70: rgba(179, 84, 20, 0.7);
  --py-color-primary-600--80: rgba(179, 84, 20, 0.8);
  --py-color-primary-600--90: rgba(179, 84, 20, 0.9);
  --py-color-primary-600: #b35414;
  --py-color-primary-500--10: rgba(224, 105, 25, 0.1);
  --py-color-primary-500--20: rgba(224, 105, 25, 0.2);
  --py-color-primary-500--30: rgba(224, 105, 25, 0.3);
  --py-color-primary-500--40: rgba(224, 105, 25, 0.4);
  --py-color-primary-500--50: rgba(224, 105, 25, 0.5);
  --py-color-primary-500--60: rgba(224, 105, 25, 0.6);
  --py-color-primary-500--70: rgba(224, 105, 25, 0.7);
  --py-color-primary-500--80: rgba(224, 105, 25, 0.8);
  --py-color-primary-500--90: rgba(224, 105, 25, 0.9);
  --py-color-primary-500: #e06919;
  --py-color-primary-400--10: rgba(230, 135, 71, 0.1);
  --py-color-primary-400--20: rgba(230, 135, 71, 0.2);
  --py-color-primary-400--30: rgba(230, 135, 71, 0.3);
  --py-color-primary-400--40: rgba(230, 135, 71, 0.4);
  --py-color-primary-400--50: rgba(230, 135, 71, 0.5);
  --py-color-primary-400--60: rgba(230, 135, 71, 0.6);
  --py-color-primary-400--70: rgba(230, 135, 71, 0.7);
  --py-color-primary-400--80: rgba(230, 135, 71, 0.8);
  --py-color-primary-400--90: rgba(230, 135, 71, 0.9);
  --py-color-primary-400: #e68747;
  --py-color-primary-300--10: rgba(236, 165, 117, 0.1);
  --py-color-primary-300--20: rgba(236, 165, 117, 0.2);
  --py-color-primary-300--30: rgba(236, 165, 117, 0.3);
  --py-color-primary-300--40: rgba(236, 165, 117, 0.4);
  --py-color-primary-300--50: rgba(236, 165, 117, 0.5);
  --py-color-primary-300--60: rgba(236, 165, 117, 0.6);
  --py-color-primary-300--70: rgba(236, 165, 117, 0.7);
  --py-color-primary-300--80: rgba(236, 165, 117, 0.8);
  --py-color-primary-300--90: rgba(236, 165, 117, 0.9);
  --py-color-primary-300: #eca575;
  --py-color-primary-200--10: rgba(247, 215, 191, 0.1);
  --py-color-primary-200--20: rgba(247, 215, 191, 0.2);
  --py-color-primary-200--30: rgba(247, 215, 191, 0.3);
  --py-color-primary-200--40: rgba(247, 215, 191, 0.4);
  --py-color-primary-200--50: rgba(247, 215, 191, 0.5);
  --py-color-primary-200--60: rgba(247, 215, 191, 0.6);
  --py-color-primary-200--70: rgba(247, 215, 191, 0.7);
  --py-color-primary-200--80: rgba(247, 215, 191, 0.8);
  --py-color-primary-200--90: rgba(247, 215, 191, 0.9);
  --py-color-primary-200: #f7d7bf;
  --py-color-primary-100--10: rgba(252, 240, 232, 0.1);
  --py-color-primary-100--20: rgba(252, 240, 232, 0.2);
  --py-color-primary-100--30: rgba(252, 240, 232, 0.3);
  --py-color-primary-100--40: rgba(252, 240, 232, 0.4);
  --py-color-primary-100--50: rgba(252, 240, 232, 0.5);
  --py-color-primary-100--60: rgba(252, 240, 232, 0.6);
  --py-color-primary-100--70: rgba(252, 240, 232, 0.7);
  --py-color-primary-100--80: rgba(252, 240, 232, 0.8);
  --py-color-primary-100--90: rgba(252, 240, 232, 0.9);
  --py-color-primary-100: #fcf0e8;
  --py-color-secondary-900--10: rgba(0, 12, 51, 0.1);
  --py-color-secondary-900--20: rgba(0, 12, 51, 0.2);
  --py-color-secondary-900--30: rgba(0, 12, 51, 0.3);
  --py-color-secondary-900--40: rgba(0, 12, 51, 0.4);
  --py-color-secondary-900--50: rgba(0, 12, 51, 0.5);
  --py-color-secondary-900--60: rgba(0, 12, 51, 0.6);
  --py-color-secondary-900--70: rgba(0, 12, 51, 0.7);
  --py-color-secondary-900--80: rgba(0, 12, 51, 0.8);
  --py-color-secondary-900--90: rgba(0, 12, 51, 0.9);
  --py-color-secondary-900: #000c33;
  --py-color-secondary-800--10: rgba(0, 24, 101, 0.1);
  --py-color-secondary-800--20: rgba(0, 24, 101, 0.2);
  --py-color-secondary-800--30: rgba(0, 24, 101, 0.3);
  --py-color-secondary-800--40: rgba(0, 24, 101, 0.4);
  --py-color-secondary-800--50: rgba(0, 24, 101, 0.5);
  --py-color-secondary-800--60: rgba(0, 24, 101, 0.6);
  --py-color-secondary-800--70: rgba(0, 24, 101, 0.7);
  --py-color-secondary-800--80: rgba(0, 24, 101, 0.8);
  --py-color-secondary-800--90: rgba(0, 24, 101, 0.9);
  --py-color-secondary-800: #001865;
  --py-color-secondary-700--10: rgba(0, 37, 152, 0.1);
  --py-color-secondary-700--20: rgba(0, 37, 152, 0.2);
  --py-color-secondary-700--30: rgba(0, 37, 152, 0.3);
  --py-color-secondary-700--40: rgba(0, 37, 152, 0.4);
  --py-color-secondary-700--50: rgba(0, 37, 152, 0.5);
  --py-color-secondary-700--60: rgba(0, 37, 152, 0.6);
  --py-color-secondary-700--70: rgba(0, 37, 152, 0.7);
  --py-color-secondary-700--80: rgba(0, 37, 152, 0.8);
  --py-color-secondary-700--90: rgba(0, 37, 152, 0.9);
  --py-color-secondary-700: #002598;
  --py-color-secondary-600--10: rgba(0, 49, 202, 0.1);
  --py-color-secondary-600--20: rgba(0, 49, 202, 0.2);
  --py-color-secondary-600--30: rgba(0, 49, 202, 0.3);
  --py-color-secondary-600--40: rgba(0, 49, 202, 0.4);
  --py-color-secondary-600--50: rgba(0, 49, 202, 0.5);
  --py-color-secondary-600--60: rgba(0, 49, 202, 0.6);
  --py-color-secondary-600--70: rgba(0, 49, 202, 0.7);
  --py-color-secondary-600--80: rgba(0, 49, 202, 0.8);
  --py-color-secondary-600--90: rgba(0, 49, 202, 0.9);
  --py-color-secondary-600: #0031ca;
  --py-color-secondary-500--10: rgba(0, 61, 253, 0.1);
  --py-color-secondary-500--20: rgba(0, 61, 253, 0.2);
  --py-color-secondary-500--30: rgba(0, 61, 253, 0.3);
  --py-color-secondary-500--40: rgba(0, 61, 253, 0.4);
  --py-color-secondary-500--50: rgba(0, 61, 253, 0.5);
  --py-color-secondary-500--60: rgba(0, 61, 253, 0.6);
  --py-color-secondary-500--70: rgba(0, 61, 253, 0.7);
  --py-color-secondary-500--80: rgba(0, 61, 253, 0.8);
  --py-color-secondary-500--90: rgba(0, 61, 253, 0.9);
  --py-color-secondary-500: #003dfd;
  --py-color-secondary-400--10: rgba(51, 100, 253, 0.1);
  --py-color-secondary-400--20: rgba(51, 100, 253, 0.2);
  --py-color-secondary-400--30: rgba(51, 100, 253, 0.3);
  --py-color-secondary-400--40: rgba(51, 100, 253, 0.4);
  --py-color-secondary-400--50: rgba(51, 100, 253, 0.5);
  --py-color-secondary-400--60: rgba(51, 100, 253, 0.6);
  --py-color-secondary-400--70: rgba(51, 100, 253, 0.7);
  --py-color-secondary-400--80: rgba(51, 100, 253, 0.8);
  --py-color-secondary-400--90: rgba(51, 100, 253, 0.9);
  --py-color-secondary-400: #3364fd;
  --py-color-secondary-300--10: rgba(102, 139, 254, 0.1);
  --py-color-secondary-300--20: rgba(102, 139, 254, 0.2);
  --py-color-secondary-300--30: rgba(102, 139, 254, 0.3);
  --py-color-secondary-300--40: rgba(102, 139, 254, 0.4);
  --py-color-secondary-300--50: rgba(102, 139, 254, 0.5);
  --py-color-secondary-300--60: rgba(102, 139, 254, 0.6);
  --py-color-secondary-300--70: rgba(102, 139, 254, 0.7);
  --py-color-secondary-300--80: rgba(102, 139, 254, 0.8);
  --py-color-secondary-300--90: rgba(102, 139, 254, 0.9);
  --py-color-secondary-300: #668bfe;
  --py-color-secondary-200--10: rgba(179, 197, 254, 0.1);
  --py-color-secondary-200--20: rgba(179, 197, 254, 0.2);
  --py-color-secondary-200--30: rgba(179, 197, 254, 0.3);
  --py-color-secondary-200--40: rgba(179, 197, 254, 0.4);
  --py-color-secondary-200--50: rgba(179, 197, 254, 0.5);
  --py-color-secondary-200--60: rgba(179, 197, 254, 0.6);
  --py-color-secondary-200--70: rgba(179, 197, 254, 0.7);
  --py-color-secondary-200--80: rgba(179, 197, 254, 0.8);
  --py-color-secondary-200--90: rgba(179, 197, 254, 0.9);
  --py-color-secondary-200: #b3c5fe;
  --py-color-secondary-100--10: rgba(234, 246, 249, 0.1);
  --py-color-secondary-100--20: rgba(234, 246, 249, 0.2);
  --py-color-secondary-100--30: rgba(234, 246, 249, 0.3);
  --py-color-secondary-100--40: rgba(234, 246, 249, 0.4);
  --py-color-secondary-100--50: rgba(234, 246, 249, 0.5);
  --py-color-secondary-100--60: rgba(234, 246, 249, 0.6);
  --py-color-secondary-100--70: rgba(234, 246, 249, 0.7);
  --py-color-secondary-100--80: rgba(234, 246, 249, 0.8);
  --py-color-secondary-100--90: rgba(234, 246, 249, 0.9);
  --py-color-secondary-100: #eaf6f9;
  --py-color-button-primary--10: rgba(20, 20, 21, 0.1);
  --py-color-button-primary--20: rgba(20, 20, 21, 0.2);
  --py-color-button-primary--30: rgba(20, 20, 21, 0.3);
  --py-color-button-primary--40: rgba(20, 20, 21, 0.4);
  --py-color-button-primary--50: rgba(20, 20, 21, 0.5);
  --py-color-button-primary--60: rgba(20, 20, 21, 0.6);
  --py-color-button-primary--70: rgba(20, 20, 21, 0.7);
  --py-color-button-primary--80: rgba(20, 20, 21, 0.8);
  --py-color-button-primary--90: rgba(20, 20, 21, 0.9);
  --py-color-button-primary: #141415;
  --py-color-button-secondary--10: rgba(224, 105, 25, 0.1);
  --py-color-button-secondary--20: rgba(224, 105, 25, 0.2);
  --py-color-button-secondary--30: rgba(224, 105, 25, 0.3);
  --py-color-button-secondary--40: rgba(224, 105, 25, 0.4);
  --py-color-button-secondary--50: rgba(224, 105, 25, 0.5);
  --py-color-button-secondary--60: rgba(224, 105, 25, 0.6);
  --py-color-button-secondary--70: rgba(224, 105, 25, 0.7);
  --py-color-button-secondary--80: rgba(224, 105, 25, 0.8);
  --py-color-button-secondary--90: rgba(224, 105, 25, 0.9);
  --py-color-button-secondary: #e06919;
  --py-color-button-danger--10: rgba(244, 63, 63, 0.1);
  --py-color-button-danger--20: rgba(244, 63, 63, 0.2);
  --py-color-button-danger--30: rgba(244, 63, 63, 0.3);
  --py-color-button-danger--40: rgba(244, 63, 63, 0.4);
  --py-color-button-danger--50: rgba(244, 63, 63, 0.5);
  --py-color-button-danger--60: rgba(244, 63, 63, 0.6);
  --py-color-button-danger--70: rgba(244, 63, 63, 0.7);
  --py-color-button-danger--80: rgba(244, 63, 63, 0.8);
  --py-color-button-danger--90: rgba(244, 63, 63, 0.9);
  --py-color-button-danger: #f43f3f;
  --py-color-button-locked--10: rgba(229, 229, 230, 0.1);
  --py-color-button-locked--20: rgba(229, 229, 230, 0.2);
  --py-color-button-locked--30: rgba(229, 229, 230, 0.3);
  --py-color-button-locked--40: rgba(229, 229, 230, 0.4);
  --py-color-button-locked--50: rgba(229, 229, 230, 0.5);
  --py-color-button-locked--60: rgba(229, 229, 230, 0.6);
  --py-color-button-locked--70: rgba(229, 229, 230, 0.7);
  --py-color-button-locked--80: rgba(229, 229, 230, 0.8);
  --py-color-button-locked--90: rgba(229, 229, 230, 0.9);
  --py-color-button-locked: #e5e5e6;
  --py-color-global-selected--10: rgba(253, 230, 206, 0.1);
  --py-color-global-selected--20: rgba(253, 230, 206, 0.2);
  --py-color-global-selected--30: rgba(253, 230, 206, 0.3);
  --py-color-global-selected--40: rgba(253, 230, 206, 0.4);
  --py-color-global-selected--50: rgba(253, 230, 206, 0.5);
  --py-color-global-selected--60: rgba(253, 230, 206, 0.6);
  --py-color-global-selected--70: rgba(253, 230, 206, 0.7);
  --py-color-global-selected--80: rgba(253, 230, 206, 0.8);
  --py-color-global-selected--90: rgba(253, 230, 206, 0.9);
  --py-color-global-selected: #fde6ce;
  --py-color-global-hover--10: rgba(255, 208, 158, 0.1);
  --py-color-global-hover--20: rgba(255, 208, 158, 0.2);
  --py-color-global-hover--30: rgba(255, 208, 158, 0.3);
  --py-color-global-hover--40: rgba(255, 208, 158, 0.4);
  --py-color-global-hover--50: rgba(255, 208, 158, 0.5);
  --py-color-global-hover--60: rgba(255, 208, 158, 0.6);
  --py-color-global-hover--70: rgba(255, 208, 158, 0.7);
  --py-color-global-hover--80: rgba(255, 208, 158, 0.8);
  --py-color-global-hover--90: rgba(255, 208, 158, 0.9);
  --py-color-global-hover: #ffd09e;
  --py-color-global-active--10: rgba(247, 143, 30, 0.1);
  --py-color-global-active--20: rgba(247, 143, 30, 0.2);
  --py-color-global-active--30: rgba(247, 143, 30, 0.3);
  --py-color-global-active--40: rgba(247, 143, 30, 0.4);
  --py-color-global-active--50: rgba(247, 143, 30, 0.5);
  --py-color-global-active--60: rgba(247, 143, 30, 0.6);
  --py-color-global-active--70: rgba(247, 143, 30, 0.7);
  --py-color-global-active--80: rgba(247, 143, 30, 0.8);
  --py-color-global-active--90: rgba(247, 143, 30, 0.9);
  --py-color-global-active: #f78f1e;
  --py-color-global-active-hover--10: rgba(148, 86, 18, 0.1);
  --py-color-global-active-hover--20: rgba(148, 86, 18, 0.2);
  --py-color-global-active-hover--30: rgba(148, 86, 18, 0.3);
  --py-color-global-active-hover--40: rgba(148, 86, 18, 0.4);
  --py-color-global-active-hover--50: rgba(148, 86, 18, 0.5);
  --py-color-global-active-hover--60: rgba(148, 86, 18, 0.6);
  --py-color-global-active-hover--70: rgba(148, 86, 18, 0.7);
  --py-color-global-active-hover--80: rgba(148, 86, 18, 0.8);
  --py-color-global-active-hover--90: rgba(148, 86, 18, 0.9);
  --py-color-global-active-hover: #945612;
  --py-color-global-tooltip--10: rgba(100, 101, 104, 0.1);
  --py-color-global-tooltip--20: rgba(100, 101, 104, 0.2);
  --py-color-global-tooltip--30: rgba(100, 101, 104, 0.3);
  --py-color-global-tooltip--40: rgba(100, 101, 104, 0.4);
  --py-color-global-tooltip--50: rgba(100, 101, 104, 0.5);
  --py-color-global-tooltip--60: rgba(100, 101, 104, 0.6);
  --py-color-global-tooltip--70: rgba(100, 101, 104, 0.7);
  --py-color-global-tooltip--80: rgba(100, 101, 104, 0.8);
  --py-color-global-tooltip--90: rgba(100, 101, 104, 0.9);
  --py-color-global-tooltip: #646568;
  --py-color-primary-dark--10: rgba(179, 84, 20, 0.1);
  --py-color-primary-dark--20: rgba(179, 84, 20, 0.2);
  --py-color-primary-dark--30: rgba(179, 84, 20, 0.3);
  --py-color-primary-dark--40: rgba(179, 84, 20, 0.4);
  --py-color-primary-dark--50: rgba(179, 84, 20, 0.5);
  --py-color-primary-dark--60: rgba(179, 84, 20, 0.6);
  --py-color-primary-dark--70: rgba(179, 84, 20, 0.7);
  --py-color-primary-dark--80: rgba(179, 84, 20, 0.8);
  --py-color-primary-dark--90: rgba(179, 84, 20, 0.9);
  --py-color-primary-dark: #b35414;
  --py-color-primary--10: rgba(224, 105, 25, 0.1);
  --py-color-primary--20: rgba(224, 105, 25, 0.2);
  --py-color-primary--30: rgba(224, 105, 25, 0.3);
  --py-color-primary--40: rgba(224, 105, 25, 0.4);
  --py-color-primary--50: rgba(224, 105, 25, 0.5);
  --py-color-primary--60: rgba(224, 105, 25, 0.6);
  --py-color-primary--70: rgba(224, 105, 25, 0.7);
  --py-color-primary--80: rgba(224, 105, 25, 0.8);
  --py-color-primary--90: rgba(224, 105, 25, 0.9);
  --py-color-primary: #e06919;
  --py-color-primary-light--10: rgba(247, 215, 191, 0.1);
  --py-color-primary-light--20: rgba(247, 215, 191, 0.2);
  --py-color-primary-light--30: rgba(247, 215, 191, 0.3);
  --py-color-primary-light--40: rgba(247, 215, 191, 0.4);
  --py-color-primary-light--50: rgba(247, 215, 191, 0.5);
  --py-color-primary-light--60: rgba(247, 215, 191, 0.6);
  --py-color-primary-light--70: rgba(247, 215, 191, 0.7);
  --py-color-primary-light--80: rgba(247, 215, 191, 0.8);
  --py-color-primary-light--90: rgba(247, 215, 191, 0.9);
  --py-color-primary-light: #f7d7bf;
  --py-color-secondary-darker--10: rgba(0, 12, 51, 0.1);
  --py-color-secondary-darker--20: rgba(0, 12, 51, 0.2);
  --py-color-secondary-darker--30: rgba(0, 12, 51, 0.3);
  --py-color-secondary-darker--40: rgba(0, 12, 51, 0.4);
  --py-color-secondary-darker--50: rgba(0, 12, 51, 0.5);
  --py-color-secondary-darker--60: rgba(0, 12, 51, 0.6);
  --py-color-secondary-darker--70: rgba(0, 12, 51, 0.7);
  --py-color-secondary-darker--80: rgba(0, 12, 51, 0.8);
  --py-color-secondary-darker--90: rgba(0, 12, 51, 0.9);
  --py-color-secondary-darker: #000c33;
  --py-color-secondary-dark--10: rgba(0, 37, 152, 0.1);
  --py-color-secondary-dark--20: rgba(0, 37, 152, 0.2);
  --py-color-secondary-dark--30: rgba(0, 37, 152, 0.3);
  --py-color-secondary-dark--40: rgba(0, 37, 152, 0.4);
  --py-color-secondary-dark--50: rgba(0, 37, 152, 0.5);
  --py-color-secondary-dark--60: rgba(0, 37, 152, 0.6);
  --py-color-secondary-dark--70: rgba(0, 37, 152, 0.7);
  --py-color-secondary-dark--80: rgba(0, 37, 152, 0.8);
  --py-color-secondary-dark--90: rgba(0, 37, 152, 0.9);
  --py-color-secondary-dark: #002598;
  --py-color-secondary--10: rgba(0, 61, 253, 0.1);
  --py-color-secondary--20: rgba(0, 61, 253, 0.2);
  --py-color-secondary--30: rgba(0, 61, 253, 0.3);
  --py-color-secondary--40: rgba(0, 61, 253, 0.4);
  --py-color-secondary--50: rgba(0, 61, 253, 0.5);
  --py-color-secondary--60: rgba(0, 61, 253, 0.6);
  --py-color-secondary--70: rgba(0, 61, 253, 0.7);
  --py-color-secondary--80: rgba(0, 61, 253, 0.8);
  --py-color-secondary--90: rgba(0, 61, 253, 0.9);
  --py-color-secondary: #003dfd;
  --py-color-secondary-light--10: rgba(102, 139, 254, 0.1);
  --py-color-secondary-light--20: rgba(102, 139, 254, 0.2);
  --py-color-secondary-light--30: rgba(102, 139, 254, 0.3);
  --py-color-secondary-light--40: rgba(102, 139, 254, 0.4);
  --py-color-secondary-light--50: rgba(102, 139, 254, 0.5);
  --py-color-secondary-light--60: rgba(102, 139, 254, 0.6);
  --py-color-secondary-light--70: rgba(102, 139, 254, 0.7);
  --py-color-secondary-light--80: rgba(102, 139, 254, 0.8);
  --py-color-secondary-light--90: rgba(102, 139, 254, 0.9);
  --py-color-secondary-light: #668bfe;
  --py-color-success--10: rgba(0, 61, 253, 0.1);
  --py-color-success--20: rgba(0, 61, 253, 0.2);
  --py-color-success--30: rgba(0, 61, 253, 0.3);
  --py-color-success--40: rgba(0, 61, 253, 0.4);
  --py-color-success--50: rgba(0, 61, 253, 0.5);
  --py-color-success--60: rgba(0, 61, 253, 0.6);
  --py-color-success--70: rgba(0, 61, 253, 0.7);
  --py-color-success--80: rgba(0, 61, 253, 0.8);
  --py-color-success--90: rgba(0, 61, 253, 0.9);
  --py-color-success: #003dfd;
  --py-color-success-secondary--10: rgba(0, 12, 51, 0.1);
  --py-color-success-secondary--20: rgba(0, 12, 51, 0.2);
  --py-color-success-secondary--30: rgba(0, 12, 51, 0.3);
  --py-color-success-secondary--40: rgba(0, 12, 51, 0.4);
  --py-color-success-secondary--50: rgba(0, 12, 51, 0.5);
  --py-color-success-secondary--60: rgba(0, 12, 51, 0.6);
  --py-color-success-secondary--70: rgba(0, 12, 51, 0.7);
  --py-color-success-secondary--80: rgba(0, 12, 51, 0.8);
  --py-color-success-secondary--90: rgba(0, 12, 51, 0.9);
  --py-color-success-secondary: #000c33;
}
:root .py-color-primary-900 {
  color: var(--py-color-primary-900);
}
:root .py-color-primary-900--10 {
  color: var(--py-color-primary-900--10);
}
:root .py-color-primary-900--20 {
  color: var(--py-color-primary-900--20);
}
:root .py-color-primary-900--30 {
  color: var(--py-color-primary-900--30);
}
:root .py-color-primary-900--40 {
  color: var(--py-color-primary-900--40);
}
:root .py-color-primary-900--50 {
  color: var(--py-color-primary-900--50);
}
:root .py-color-primary-900--60 {
  color: var(--py-color-primary-900--60);
}
:root .py-color-primary-900--70 {
  color: var(--py-color-primary-900--70);
}
:root .py-color-primary-900--80 {
  color: var(--py-color-primary-900--80);
}
:root .py-color-primary-900--90 {
  color: var(--py-color-primary-900--90);
}
:root .py-color-primary-800 {
  color: var(--py-color-primary-800);
}
:root .py-color-primary-800--10 {
  color: var(--py-color-primary-800--10);
}
:root .py-color-primary-800--20 {
  color: var(--py-color-primary-800--20);
}
:root .py-color-primary-800--30 {
  color: var(--py-color-primary-800--30);
}
:root .py-color-primary-800--40 {
  color: var(--py-color-primary-800--40);
}
:root .py-color-primary-800--50 {
  color: var(--py-color-primary-800--50);
}
:root .py-color-primary-800--60 {
  color: var(--py-color-primary-800--60);
}
:root .py-color-primary-800--70 {
  color: var(--py-color-primary-800--70);
}
:root .py-color-primary-800--80 {
  color: var(--py-color-primary-800--80);
}
:root .py-color-primary-800--90 {
  color: var(--py-color-primary-800--90);
}
:root .py-color-primary-700 {
  color: var(--py-color-primary-700);
}
:root .py-color-primary-700--10 {
  color: var(--py-color-primary-700--10);
}
:root .py-color-primary-700--20 {
  color: var(--py-color-primary-700--20);
}
:root .py-color-primary-700--30 {
  color: var(--py-color-primary-700--30);
}
:root .py-color-primary-700--40 {
  color: var(--py-color-primary-700--40);
}
:root .py-color-primary-700--50 {
  color: var(--py-color-primary-700--50);
}
:root .py-color-primary-700--60 {
  color: var(--py-color-primary-700--60);
}
:root .py-color-primary-700--70 {
  color: var(--py-color-primary-700--70);
}
:root .py-color-primary-700--80 {
  color: var(--py-color-primary-700--80);
}
:root .py-color-primary-700--90 {
  color: var(--py-color-primary-700--90);
}
:root .py-color-primary-600 {
  color: var(--py-color-primary-600);
}
:root .py-color-primary-600--10 {
  color: var(--py-color-primary-600--10);
}
:root .py-color-primary-600--20 {
  color: var(--py-color-primary-600--20);
}
:root .py-color-primary-600--30 {
  color: var(--py-color-primary-600--30);
}
:root .py-color-primary-600--40 {
  color: var(--py-color-primary-600--40);
}
:root .py-color-primary-600--50 {
  color: var(--py-color-primary-600--50);
}
:root .py-color-primary-600--60 {
  color: var(--py-color-primary-600--60);
}
:root .py-color-primary-600--70 {
  color: var(--py-color-primary-600--70);
}
:root .py-color-primary-600--80 {
  color: var(--py-color-primary-600--80);
}
:root .py-color-primary-600--90 {
  color: var(--py-color-primary-600--90);
}
:root .py-color-primary-500 {
  color: var(--py-color-primary-500);
}
:root .py-color-primary-500--10 {
  color: var(--py-color-primary-500--10);
}
:root .py-color-primary-500--20 {
  color: var(--py-color-primary-500--20);
}
:root .py-color-primary-500--30 {
  color: var(--py-color-primary-500--30);
}
:root .py-color-primary-500--40 {
  color: var(--py-color-primary-500--40);
}
:root .py-color-primary-500--50 {
  color: var(--py-color-primary-500--50);
}
:root .py-color-primary-500--60 {
  color: var(--py-color-primary-500--60);
}
:root .py-color-primary-500--70 {
  color: var(--py-color-primary-500--70);
}
:root .py-color-primary-500--80 {
  color: var(--py-color-primary-500--80);
}
:root .py-color-primary-500--90 {
  color: var(--py-color-primary-500--90);
}
:root .py-color-primary-400 {
  color: var(--py-color-primary-400);
}
:root .py-color-primary-400--10 {
  color: var(--py-color-primary-400--10);
}
:root .py-color-primary-400--20 {
  color: var(--py-color-primary-400--20);
}
:root .py-color-primary-400--30 {
  color: var(--py-color-primary-400--30);
}
:root .py-color-primary-400--40 {
  color: var(--py-color-primary-400--40);
}
:root .py-color-primary-400--50 {
  color: var(--py-color-primary-400--50);
}
:root .py-color-primary-400--60 {
  color: var(--py-color-primary-400--60);
}
:root .py-color-primary-400--70 {
  color: var(--py-color-primary-400--70);
}
:root .py-color-primary-400--80 {
  color: var(--py-color-primary-400--80);
}
:root .py-color-primary-400--90 {
  color: var(--py-color-primary-400--90);
}
:root .py-color-primary-300 {
  color: var(--py-color-primary-300);
}
:root .py-color-primary-300--10 {
  color: var(--py-color-primary-300--10);
}
:root .py-color-primary-300--20 {
  color: var(--py-color-primary-300--20);
}
:root .py-color-primary-300--30 {
  color: var(--py-color-primary-300--30);
}
:root .py-color-primary-300--40 {
  color: var(--py-color-primary-300--40);
}
:root .py-color-primary-300--50 {
  color: var(--py-color-primary-300--50);
}
:root .py-color-primary-300--60 {
  color: var(--py-color-primary-300--60);
}
:root .py-color-primary-300--70 {
  color: var(--py-color-primary-300--70);
}
:root .py-color-primary-300--80 {
  color: var(--py-color-primary-300--80);
}
:root .py-color-primary-300--90 {
  color: var(--py-color-primary-300--90);
}
:root .py-color-primary-200 {
  color: var(--py-color-primary-200);
}
:root .py-color-primary-200--10 {
  color: var(--py-color-primary-200--10);
}
:root .py-color-primary-200--20 {
  color: var(--py-color-primary-200--20);
}
:root .py-color-primary-200--30 {
  color: var(--py-color-primary-200--30);
}
:root .py-color-primary-200--40 {
  color: var(--py-color-primary-200--40);
}
:root .py-color-primary-200--50 {
  color: var(--py-color-primary-200--50);
}
:root .py-color-primary-200--60 {
  color: var(--py-color-primary-200--60);
}
:root .py-color-primary-200--70 {
  color: var(--py-color-primary-200--70);
}
:root .py-color-primary-200--80 {
  color: var(--py-color-primary-200--80);
}
:root .py-color-primary-200--90 {
  color: var(--py-color-primary-200--90);
}
:root .py-color-primary-100 {
  color: var(--py-color-primary-100);
}
:root .py-color-primary-100--10 {
  color: var(--py-color-primary-100--10);
}
:root .py-color-primary-100--20 {
  color: var(--py-color-primary-100--20);
}
:root .py-color-primary-100--30 {
  color: var(--py-color-primary-100--30);
}
:root .py-color-primary-100--40 {
  color: var(--py-color-primary-100--40);
}
:root .py-color-primary-100--50 {
  color: var(--py-color-primary-100--50);
}
:root .py-color-primary-100--60 {
  color: var(--py-color-primary-100--60);
}
:root .py-color-primary-100--70 {
  color: var(--py-color-primary-100--70);
}
:root .py-color-primary-100--80 {
  color: var(--py-color-primary-100--80);
}
:root .py-color-primary-100--90 {
  color: var(--py-color-primary-100--90);
}
:root .py-color-secondary-900 {
  color: var(--py-color-secondary-900);
}
:root .py-color-secondary-900--10 {
  color: var(--py-color-secondary-900--10);
}
:root .py-color-secondary-900--20 {
  color: var(--py-color-secondary-900--20);
}
:root .py-color-secondary-900--30 {
  color: var(--py-color-secondary-900--30);
}
:root .py-color-secondary-900--40 {
  color: var(--py-color-secondary-900--40);
}
:root .py-color-secondary-900--50 {
  color: var(--py-color-secondary-900--50);
}
:root .py-color-secondary-900--60 {
  color: var(--py-color-secondary-900--60);
}
:root .py-color-secondary-900--70 {
  color: var(--py-color-secondary-900--70);
}
:root .py-color-secondary-900--80 {
  color: var(--py-color-secondary-900--80);
}
:root .py-color-secondary-900--90 {
  color: var(--py-color-secondary-900--90);
}
:root .py-color-secondary-800 {
  color: var(--py-color-secondary-800);
}
:root .py-color-secondary-800--10 {
  color: var(--py-color-secondary-800--10);
}
:root .py-color-secondary-800--20 {
  color: var(--py-color-secondary-800--20);
}
:root .py-color-secondary-800--30 {
  color: var(--py-color-secondary-800--30);
}
:root .py-color-secondary-800--40 {
  color: var(--py-color-secondary-800--40);
}
:root .py-color-secondary-800--50 {
  color: var(--py-color-secondary-800--50);
}
:root .py-color-secondary-800--60 {
  color: var(--py-color-secondary-800--60);
}
:root .py-color-secondary-800--70 {
  color: var(--py-color-secondary-800--70);
}
:root .py-color-secondary-800--80 {
  color: var(--py-color-secondary-800--80);
}
:root .py-color-secondary-800--90 {
  color: var(--py-color-secondary-800--90);
}
:root .py-color-secondary-700 {
  color: var(--py-color-secondary-700);
}
:root .py-color-secondary-700--10 {
  color: var(--py-color-secondary-700--10);
}
:root .py-color-secondary-700--20 {
  color: var(--py-color-secondary-700--20);
}
:root .py-color-secondary-700--30 {
  color: var(--py-color-secondary-700--30);
}
:root .py-color-secondary-700--40 {
  color: var(--py-color-secondary-700--40);
}
:root .py-color-secondary-700--50 {
  color: var(--py-color-secondary-700--50);
}
:root .py-color-secondary-700--60 {
  color: var(--py-color-secondary-700--60);
}
:root .py-color-secondary-700--70 {
  color: var(--py-color-secondary-700--70);
}
:root .py-color-secondary-700--80 {
  color: var(--py-color-secondary-700--80);
}
:root .py-color-secondary-700--90 {
  color: var(--py-color-secondary-700--90);
}
:root .py-color-secondary-600 {
  color: var(--py-color-secondary-600);
}
:root .py-color-secondary-600--10 {
  color: var(--py-color-secondary-600--10);
}
:root .py-color-secondary-600--20 {
  color: var(--py-color-secondary-600--20);
}
:root .py-color-secondary-600--30 {
  color: var(--py-color-secondary-600--30);
}
:root .py-color-secondary-600--40 {
  color: var(--py-color-secondary-600--40);
}
:root .py-color-secondary-600--50 {
  color: var(--py-color-secondary-600--50);
}
:root .py-color-secondary-600--60 {
  color: var(--py-color-secondary-600--60);
}
:root .py-color-secondary-600--70 {
  color: var(--py-color-secondary-600--70);
}
:root .py-color-secondary-600--80 {
  color: var(--py-color-secondary-600--80);
}
:root .py-color-secondary-600--90 {
  color: var(--py-color-secondary-600--90);
}
:root .py-color-secondary-500 {
  color: var(--py-color-secondary-500);
}
:root .py-color-secondary-500--10 {
  color: var(--py-color-secondary-500--10);
}
:root .py-color-secondary-500--20 {
  color: var(--py-color-secondary-500--20);
}
:root .py-color-secondary-500--30 {
  color: var(--py-color-secondary-500--30);
}
:root .py-color-secondary-500--40 {
  color: var(--py-color-secondary-500--40);
}
:root .py-color-secondary-500--50 {
  color: var(--py-color-secondary-500--50);
}
:root .py-color-secondary-500--60 {
  color: var(--py-color-secondary-500--60);
}
:root .py-color-secondary-500--70 {
  color: var(--py-color-secondary-500--70);
}
:root .py-color-secondary-500--80 {
  color: var(--py-color-secondary-500--80);
}
:root .py-color-secondary-500--90 {
  color: var(--py-color-secondary-500--90);
}
:root .py-color-secondary-400 {
  color: var(--py-color-secondary-400);
}
:root .py-color-secondary-400--10 {
  color: var(--py-color-secondary-400--10);
}
:root .py-color-secondary-400--20 {
  color: var(--py-color-secondary-400--20);
}
:root .py-color-secondary-400--30 {
  color: var(--py-color-secondary-400--30);
}
:root .py-color-secondary-400--40 {
  color: var(--py-color-secondary-400--40);
}
:root .py-color-secondary-400--50 {
  color: var(--py-color-secondary-400--50);
}
:root .py-color-secondary-400--60 {
  color: var(--py-color-secondary-400--60);
}
:root .py-color-secondary-400--70 {
  color: var(--py-color-secondary-400--70);
}
:root .py-color-secondary-400--80 {
  color: var(--py-color-secondary-400--80);
}
:root .py-color-secondary-400--90 {
  color: var(--py-color-secondary-400--90);
}
:root .py-color-secondary-300 {
  color: var(--py-color-secondary-300);
}
:root .py-color-secondary-300--10 {
  color: var(--py-color-secondary-300--10);
}
:root .py-color-secondary-300--20 {
  color: var(--py-color-secondary-300--20);
}
:root .py-color-secondary-300--30 {
  color: var(--py-color-secondary-300--30);
}
:root .py-color-secondary-300--40 {
  color: var(--py-color-secondary-300--40);
}
:root .py-color-secondary-300--50 {
  color: var(--py-color-secondary-300--50);
}
:root .py-color-secondary-300--60 {
  color: var(--py-color-secondary-300--60);
}
:root .py-color-secondary-300--70 {
  color: var(--py-color-secondary-300--70);
}
:root .py-color-secondary-300--80 {
  color: var(--py-color-secondary-300--80);
}
:root .py-color-secondary-300--90 {
  color: var(--py-color-secondary-300--90);
}
:root .py-color-secondary-200 {
  color: var(--py-color-secondary-200);
}
:root .py-color-secondary-200--10 {
  color: var(--py-color-secondary-200--10);
}
:root .py-color-secondary-200--20 {
  color: var(--py-color-secondary-200--20);
}
:root .py-color-secondary-200--30 {
  color: var(--py-color-secondary-200--30);
}
:root .py-color-secondary-200--40 {
  color: var(--py-color-secondary-200--40);
}
:root .py-color-secondary-200--50 {
  color: var(--py-color-secondary-200--50);
}
:root .py-color-secondary-200--60 {
  color: var(--py-color-secondary-200--60);
}
:root .py-color-secondary-200--70 {
  color: var(--py-color-secondary-200--70);
}
:root .py-color-secondary-200--80 {
  color: var(--py-color-secondary-200--80);
}
:root .py-color-secondary-200--90 {
  color: var(--py-color-secondary-200--90);
}
:root .py-color-secondary-100 {
  color: var(--py-color-secondary-100);
}
:root .py-color-secondary-100--10 {
  color: var(--py-color-secondary-100--10);
}
:root .py-color-secondary-100--20 {
  color: var(--py-color-secondary-100--20);
}
:root .py-color-secondary-100--30 {
  color: var(--py-color-secondary-100--30);
}
:root .py-color-secondary-100--40 {
  color: var(--py-color-secondary-100--40);
}
:root .py-color-secondary-100--50 {
  color: var(--py-color-secondary-100--50);
}
:root .py-color-secondary-100--60 {
  color: var(--py-color-secondary-100--60);
}
:root .py-color-secondary-100--70 {
  color: var(--py-color-secondary-100--70);
}
:root .py-color-secondary-100--80 {
  color: var(--py-color-secondary-100--80);
}
:root .py-color-secondary-100--90 {
  color: var(--py-color-secondary-100--90);
}
:root .py-color-button-primary {
  color: var(--py-color-button-primary);
}
:root .py-color-button-primary--10 {
  color: var(--py-color-button-primary--10);
}
:root .py-color-button-primary--20 {
  color: var(--py-color-button-primary--20);
}
:root .py-color-button-primary--30 {
  color: var(--py-color-button-primary--30);
}
:root .py-color-button-primary--40 {
  color: var(--py-color-button-primary--40);
}
:root .py-color-button-primary--50 {
  color: var(--py-color-button-primary--50);
}
:root .py-color-button-primary--60 {
  color: var(--py-color-button-primary--60);
}
:root .py-color-button-primary--70 {
  color: var(--py-color-button-primary--70);
}
:root .py-color-button-primary--80 {
  color: var(--py-color-button-primary--80);
}
:root .py-color-button-primary--90 {
  color: var(--py-color-button-primary--90);
}
:root .py-color-button-secondary {
  color: var(--py-color-button-secondary);
}
:root .py-color-button-secondary--10 {
  color: var(--py-color-button-secondary--10);
}
:root .py-color-button-secondary--20 {
  color: var(--py-color-button-secondary--20);
}
:root .py-color-button-secondary--30 {
  color: var(--py-color-button-secondary--30);
}
:root .py-color-button-secondary--40 {
  color: var(--py-color-button-secondary--40);
}
:root .py-color-button-secondary--50 {
  color: var(--py-color-button-secondary--50);
}
:root .py-color-button-secondary--60 {
  color: var(--py-color-button-secondary--60);
}
:root .py-color-button-secondary--70 {
  color: var(--py-color-button-secondary--70);
}
:root .py-color-button-secondary--80 {
  color: var(--py-color-button-secondary--80);
}
:root .py-color-button-secondary--90 {
  color: var(--py-color-button-secondary--90);
}
:root .py-color-button-danger {
  color: var(--py-color-button-danger);
}
:root .py-color-button-danger--10 {
  color: var(--py-color-button-danger--10);
}
:root .py-color-button-danger--20 {
  color: var(--py-color-button-danger--20);
}
:root .py-color-button-danger--30 {
  color: var(--py-color-button-danger--30);
}
:root .py-color-button-danger--40 {
  color: var(--py-color-button-danger--40);
}
:root .py-color-button-danger--50 {
  color: var(--py-color-button-danger--50);
}
:root .py-color-button-danger--60 {
  color: var(--py-color-button-danger--60);
}
:root .py-color-button-danger--70 {
  color: var(--py-color-button-danger--70);
}
:root .py-color-button-danger--80 {
  color: var(--py-color-button-danger--80);
}
:root .py-color-button-danger--90 {
  color: var(--py-color-button-danger--90);
}
:root .py-color-button-locked {
  color: var(--py-color-button-locked);
}
:root .py-color-button-locked--10 {
  color: var(--py-color-button-locked--10);
}
:root .py-color-button-locked--20 {
  color: var(--py-color-button-locked--20);
}
:root .py-color-button-locked--30 {
  color: var(--py-color-button-locked--30);
}
:root .py-color-button-locked--40 {
  color: var(--py-color-button-locked--40);
}
:root .py-color-button-locked--50 {
  color: var(--py-color-button-locked--50);
}
:root .py-color-button-locked--60 {
  color: var(--py-color-button-locked--60);
}
:root .py-color-button-locked--70 {
  color: var(--py-color-button-locked--70);
}
:root .py-color-button-locked--80 {
  color: var(--py-color-button-locked--80);
}
:root .py-color-button-locked--90 {
  color: var(--py-color-button-locked--90);
}
:root .py-color-global-selected {
  color: var(--py-color-global-selected);
}
:root .py-color-global-selected--10 {
  color: var(--py-color-global-selected--10);
}
:root .py-color-global-selected--20 {
  color: var(--py-color-global-selected--20);
}
:root .py-color-global-selected--30 {
  color: var(--py-color-global-selected--30);
}
:root .py-color-global-selected--40 {
  color: var(--py-color-global-selected--40);
}
:root .py-color-global-selected--50 {
  color: var(--py-color-global-selected--50);
}
:root .py-color-global-selected--60 {
  color: var(--py-color-global-selected--60);
}
:root .py-color-global-selected--70 {
  color: var(--py-color-global-selected--70);
}
:root .py-color-global-selected--80 {
  color: var(--py-color-global-selected--80);
}
:root .py-color-global-selected--90 {
  color: var(--py-color-global-selected--90);
}
:root .py-color-global-hover {
  color: var(--py-color-global-hover);
}
:root .py-color-global-hover--10 {
  color: var(--py-color-global-hover--10);
}
:root .py-color-global-hover--20 {
  color: var(--py-color-global-hover--20);
}
:root .py-color-global-hover--30 {
  color: var(--py-color-global-hover--30);
}
:root .py-color-global-hover--40 {
  color: var(--py-color-global-hover--40);
}
:root .py-color-global-hover--50 {
  color: var(--py-color-global-hover--50);
}
:root .py-color-global-hover--60 {
  color: var(--py-color-global-hover--60);
}
:root .py-color-global-hover--70 {
  color: var(--py-color-global-hover--70);
}
:root .py-color-global-hover--80 {
  color: var(--py-color-global-hover--80);
}
:root .py-color-global-hover--90 {
  color: var(--py-color-global-hover--90);
}
:root .py-color-global-active {
  color: var(--py-color-global-active);
}
:root .py-color-global-active--10 {
  color: var(--py-color-global-active--10);
}
:root .py-color-global-active--20 {
  color: var(--py-color-global-active--20);
}
:root .py-color-global-active--30 {
  color: var(--py-color-global-active--30);
}
:root .py-color-global-active--40 {
  color: var(--py-color-global-active--40);
}
:root .py-color-global-active--50 {
  color: var(--py-color-global-active--50);
}
:root .py-color-global-active--60 {
  color: var(--py-color-global-active--60);
}
:root .py-color-global-active--70 {
  color: var(--py-color-global-active--70);
}
:root .py-color-global-active--80 {
  color: var(--py-color-global-active--80);
}
:root .py-color-global-active--90 {
  color: var(--py-color-global-active--90);
}
:root .py-color-global-active-hover {
  color: var(--py-color-global-active-hover);
}
:root .py-color-global-active-hover--10 {
  color: var(--py-color-global-active-hover--10);
}
:root .py-color-global-active-hover--20 {
  color: var(--py-color-global-active-hover--20);
}
:root .py-color-global-active-hover--30 {
  color: var(--py-color-global-active-hover--30);
}
:root .py-color-global-active-hover--40 {
  color: var(--py-color-global-active-hover--40);
}
:root .py-color-global-active-hover--50 {
  color: var(--py-color-global-active-hover--50);
}
:root .py-color-global-active-hover--60 {
  color: var(--py-color-global-active-hover--60);
}
:root .py-color-global-active-hover--70 {
  color: var(--py-color-global-active-hover--70);
}
:root .py-color-global-active-hover--80 {
  color: var(--py-color-global-active-hover--80);
}
:root .py-color-global-active-hover--90 {
  color: var(--py-color-global-active-hover--90);
}
:root .py-color-global-tooltip {
  color: var(--py-color-global-tooltip);
}
:root .py-color-global-tooltip--10 {
  color: var(--py-color-global-tooltip--10);
}
:root .py-color-global-tooltip--20 {
  color: var(--py-color-global-tooltip--20);
}
:root .py-color-global-tooltip--30 {
  color: var(--py-color-global-tooltip--30);
}
:root .py-color-global-tooltip--40 {
  color: var(--py-color-global-tooltip--40);
}
:root .py-color-global-tooltip--50 {
  color: var(--py-color-global-tooltip--50);
}
:root .py-color-global-tooltip--60 {
  color: var(--py-color-global-tooltip--60);
}
:root .py-color-global-tooltip--70 {
  color: var(--py-color-global-tooltip--70);
}
:root .py-color-global-tooltip--80 {
  color: var(--py-color-global-tooltip--80);
}
:root .py-color-global-tooltip--90 {
  color: var(--py-color-global-tooltip--90);
}
:root .py-color-primary-dark {
  color: var(--py-color-primary-dark);
}
:root .py-color-primary-dark--10 {
  color: var(--py-color-primary-dark--10);
}
:root .py-color-primary-dark--20 {
  color: var(--py-color-primary-dark--20);
}
:root .py-color-primary-dark--30 {
  color: var(--py-color-primary-dark--30);
}
:root .py-color-primary-dark--40 {
  color: var(--py-color-primary-dark--40);
}
:root .py-color-primary-dark--50 {
  color: var(--py-color-primary-dark--50);
}
:root .py-color-primary-dark--60 {
  color: var(--py-color-primary-dark--60);
}
:root .py-color-primary-dark--70 {
  color: var(--py-color-primary-dark--70);
}
:root .py-color-primary-dark--80 {
  color: var(--py-color-primary-dark--80);
}
:root .py-color-primary-dark--90 {
  color: var(--py-color-primary-dark--90);
}
:root .py-color-primary {
  color: var(--py-color-primary);
}
:root .py-color-primary--10 {
  color: var(--py-color-primary--10);
}
:root .py-color-primary--20 {
  color: var(--py-color-primary--20);
}
:root .py-color-primary--30 {
  color: var(--py-color-primary--30);
}
:root .py-color-primary--40 {
  color: var(--py-color-primary--40);
}
:root .py-color-primary--50 {
  color: var(--py-color-primary--50);
}
:root .py-color-primary--60 {
  color: var(--py-color-primary--60);
}
:root .py-color-primary--70 {
  color: var(--py-color-primary--70);
}
:root .py-color-primary--80 {
  color: var(--py-color-primary--80);
}
:root .py-color-primary--90 {
  color: var(--py-color-primary--90);
}
:root .py-color-primary-light {
  color: var(--py-color-primary-light);
}
:root .py-color-primary-light--10 {
  color: var(--py-color-primary-light--10);
}
:root .py-color-primary-light--20 {
  color: var(--py-color-primary-light--20);
}
:root .py-color-primary-light--30 {
  color: var(--py-color-primary-light--30);
}
:root .py-color-primary-light--40 {
  color: var(--py-color-primary-light--40);
}
:root .py-color-primary-light--50 {
  color: var(--py-color-primary-light--50);
}
:root .py-color-primary-light--60 {
  color: var(--py-color-primary-light--60);
}
:root .py-color-primary-light--70 {
  color: var(--py-color-primary-light--70);
}
:root .py-color-primary-light--80 {
  color: var(--py-color-primary-light--80);
}
:root .py-color-primary-light--90 {
  color: var(--py-color-primary-light--90);
}
:root .py-color-secondary-darker {
  color: var(--py-color-secondary-darker);
}
:root .py-color-secondary-darker--10 {
  color: var(--py-color-secondary-darker--10);
}
:root .py-color-secondary-darker--20 {
  color: var(--py-color-secondary-darker--20);
}
:root .py-color-secondary-darker--30 {
  color: var(--py-color-secondary-darker--30);
}
:root .py-color-secondary-darker--40 {
  color: var(--py-color-secondary-darker--40);
}
:root .py-color-secondary-darker--50 {
  color: var(--py-color-secondary-darker--50);
}
:root .py-color-secondary-darker--60 {
  color: var(--py-color-secondary-darker--60);
}
:root .py-color-secondary-darker--70 {
  color: var(--py-color-secondary-darker--70);
}
:root .py-color-secondary-darker--80 {
  color: var(--py-color-secondary-darker--80);
}
:root .py-color-secondary-darker--90 {
  color: var(--py-color-secondary-darker--90);
}
:root .py-color-secondary-dark {
  color: var(--py-color-secondary-dark);
}
:root .py-color-secondary-dark--10 {
  color: var(--py-color-secondary-dark--10);
}
:root .py-color-secondary-dark--20 {
  color: var(--py-color-secondary-dark--20);
}
:root .py-color-secondary-dark--30 {
  color: var(--py-color-secondary-dark--30);
}
:root .py-color-secondary-dark--40 {
  color: var(--py-color-secondary-dark--40);
}
:root .py-color-secondary-dark--50 {
  color: var(--py-color-secondary-dark--50);
}
:root .py-color-secondary-dark--60 {
  color: var(--py-color-secondary-dark--60);
}
:root .py-color-secondary-dark--70 {
  color: var(--py-color-secondary-dark--70);
}
:root .py-color-secondary-dark--80 {
  color: var(--py-color-secondary-dark--80);
}
:root .py-color-secondary-dark--90 {
  color: var(--py-color-secondary-dark--90);
}
:root .py-color-secondary {
  color: var(--py-color-secondary);
}
:root .py-color-secondary--10 {
  color: var(--py-color-secondary--10);
}
:root .py-color-secondary--20 {
  color: var(--py-color-secondary--20);
}
:root .py-color-secondary--30 {
  color: var(--py-color-secondary--30);
}
:root .py-color-secondary--40 {
  color: var(--py-color-secondary--40);
}
:root .py-color-secondary--50 {
  color: var(--py-color-secondary--50);
}
:root .py-color-secondary--60 {
  color: var(--py-color-secondary--60);
}
:root .py-color-secondary--70 {
  color: var(--py-color-secondary--70);
}
:root .py-color-secondary--80 {
  color: var(--py-color-secondary--80);
}
:root .py-color-secondary--90 {
  color: var(--py-color-secondary--90);
}
:root .py-color-secondary-light {
  color: var(--py-color-secondary-light);
}
:root .py-color-secondary-light--10 {
  color: var(--py-color-secondary-light--10);
}
:root .py-color-secondary-light--20 {
  color: var(--py-color-secondary-light--20);
}
:root .py-color-secondary-light--30 {
  color: var(--py-color-secondary-light--30);
}
:root .py-color-secondary-light--40 {
  color: var(--py-color-secondary-light--40);
}
:root .py-color-secondary-light--50 {
  color: var(--py-color-secondary-light--50);
}
:root .py-color-secondary-light--60 {
  color: var(--py-color-secondary-light--60);
}
:root .py-color-secondary-light--70 {
  color: var(--py-color-secondary-light--70);
}
:root .py-color-secondary-light--80 {
  color: var(--py-color-secondary-light--80);
}
:root .py-color-secondary-light--90 {
  color: var(--py-color-secondary-light--90);
}
:root .py-color-success {
  color: var(--py-color-success);
}
:root .py-color-success--10 {
  color: var(--py-color-success--10);
}
:root .py-color-success--20 {
  color: var(--py-color-success--20);
}
:root .py-color-success--30 {
  color: var(--py-color-success--30);
}
:root .py-color-success--40 {
  color: var(--py-color-success--40);
}
:root .py-color-success--50 {
  color: var(--py-color-success--50);
}
:root .py-color-success--60 {
  color: var(--py-color-success--60);
}
:root .py-color-success--70 {
  color: var(--py-color-success--70);
}
:root .py-color-success--80 {
  color: var(--py-color-success--80);
}
:root .py-color-success--90 {
  color: var(--py-color-success--90);
}
:root .py-color-success-secondary {
  color: var(--py-color-success-secondary);
}
:root .py-color-success-secondary--10 {
  color: var(--py-color-success-secondary--10);
}
:root .py-color-success-secondary--20 {
  color: var(--py-color-success-secondary--20);
}
:root .py-color-success-secondary--30 {
  color: var(--py-color-success-secondary--30);
}
:root .py-color-success-secondary--40 {
  color: var(--py-color-success-secondary--40);
}
:root .py-color-success-secondary--50 {
  color: var(--py-color-success-secondary--50);
}
:root .py-color-success-secondary--60 {
  color: var(--py-color-success-secondary--60);
}
:root .py-color-success-secondary--70 {
  color: var(--py-color-success-secondary--70);
}
:root .py-color-success-secondary--80 {
  color: var(--py-color-success-secondary--80);
}
:root .py-color-success-secondary--90 {
  color: var(--py-color-success-secondary--90);
}

.btn-primary {
  color: var(--py-color-white);
  background-color: var(--py-color-button-primary);
  border-color: var(--py-color-button-primary);
  box-shadow: 0 2px 4px rgba(20, 20, 21, 0.2);
}
.btn-primary:hover {
  background-color: var(--py-color-button-primary);
  border-color: var(--py-color-grey-600);
  box-shadow: 0 4px 8px rgba(20, 20, 21, 0.4);
  text-decoration: underline;
}
.btn-primary:focus {
  background-color: var(--py-color-button-primary) !important;
  border-color: var(--py-color-grey-600) !important;
}
.btn-primary:active {
  background-color: var(--py-color-button-primary) !important;
  border-color: var(--py-color-grey-600) !important;
  box-shadow: 0 4px 8px rgba(20, 20, 21, 0.4);
}
.btn-primary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-secondary {
  color: var(--py-color-white);
  background-color: var(--py-color-button-secondary);
  border-color: var(--py-color-button-secondary);
  box-shadow: 0 2px 4px rgba(224, 105, 25, 0.2);
}
.btn-secondary:hover {
  background-color: var(--py-color-button-secondary);
  border-color: var(--py-color-primary-800);
  box-shadow: 0 4px 8px rgba(224, 105, 25, 0.4);
  text-decoration: underline;
}
.btn-secondary:focus {
  background-color: var(--py-color-button-secondary) !important;
  border-color: var(--py-color-primary-800) !important;
}
.btn-secondary:active {
  background-color: var(--py-color-button-secondary) !important;
  border-color: var(--py-color-primary-800) !important;
  box-shadow: 0 4px 8px rgba(224, 105, 25, 0.4);
}
.btn-secondary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-primary {
  color: var(--py-color-button-primary);
  border-color: var(--py-color-line-soft);
  background-color: var(--py-color-white);
  box-shadow: none !important;
}
.btn-plain-primary:hover {
  text-decoration: underline;
}
.btn-plain-primary:hover, .btn-plain-primary:active, .btn-plain-primary:focus {
  color: var(--py-color-button-primary);
  background-color: var(--py-color-white);
  border-color: var(--py-color-global-active);
}
.btn-plain-primary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-primary:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-plain-primary:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-plain-primary:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-plain-secondary {
  color: var(--py-color-black);
  border-color: var(--py-color-line-soft);
  background-color: var(--py-color-white);
  box-shadow: none !important;
}
.btn-plain-secondary:hover {
  text-decoration: underline;
}
.btn-plain-secondary:hover, .btn-plain-secondary:active, .btn-plain-secondary:focus {
  color: var(--py-color-black);
  background-color: var(--py-color-white);
  border-color: var(--py-color-global-active);
}
.btn-plain-secondary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-secondary:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-plain-secondary:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-plain-secondary:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-plain-danger {
  color: var(--py-color-button-danger);
  border-color: var(--py-color-line-soft);
  background-color: var(--py-color-white);
  box-shadow: none !important;
}
.btn-plain-danger:hover {
  text-decoration: underline;
}
.btn-plain-danger:hover, .btn-plain-danger:active, .btn-plain-danger:focus {
  color: var(--py-color-button-danger);
  background-color: var(--py-color-white);
  border-color: var(--py-color-global-active);
}
.btn-plain-danger:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-danger:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-plain-danger:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-plain-danger:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-ternary {
  color: var(--py-color-black);
  background-color: var(--py-color-white);
  border-color: var(--py-color-grey-light);
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
  box-shadow: none !important;
}
.btn-ternary:hover {
  background-color: var(--py-color-white);
  border-color: var(--py-color-primary);
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
  text-decoration: underline;
}
.btn-ternary:focus {
  background-color: var(--py-color-white) !important;
  border-color: var(--py-color-primary) !important;
}
.btn-ternary:active {
  background-color: var(--py-color-grey-lighter) !important;
  border-color: var(--py-color-primary) !important;
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
}
.btn-ternary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-ternary:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-ternary:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-ternary:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-ternary:disabled {
  border: 2px solid var(--py-color-grey-light);
}

py-mail-form .mail-form.mailform-newsletter {
  max-width: none;
  border-top: none !important;
  background-color: var(--py-color-grey-100);
  background-size: contain;
}
py-mail-form .mail-form.mailform-newsletter > form {
  background-color: unset !important;
  padding: 0 !important;
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}
py-mail-form .mail-form.mailform-newsletter > form .lead-signup-label {
  font-family: var(--py-font-secondary-bold);
}
py-mail-form .mail-form.mailform-newsletter > form .sub-signup-label {
  padding-bottom: 16px !important;
}
py-mail-form .mail-form.mailform-newsletter > form .email-container {
  display: flex;
  position: unset !important;
  width: unset !important;
  left: unset !important;
  top: unset !important;
}
py-mail-form .mail-form.mailform-newsletter > form .email-container py-reactive-form-input-field {
  margin-right: 0.5rem;
}
@media (max-width: 991.98px) {
  py-mail-form .mail-form.mailform-newsletter > form .email-container {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button {
    max-width: none !important;
    margin-top: 0.5rem;
  }
}
py-mail-form .mail-form.mailform-newsletter > form .bottom-signup-label {
  position: unset !important;
  width: unset !important;
  left: unset !important;
  top: unset !important;
  margin-top: 1rem;
}

py-cart-message py-cart-message-modal {
  background-color: var(--py-color-primary-500) !important;
}

.current-customer-banner {
  background-color: var(--py-color-grey-900) !important;
}

cx-page-layout.footer {
  background-color: var(--py-color-black);
}

py-footer-navigation {
  background-color: var(--py-color-black) !important;
}
py-footer-navigation .container {
  padding-bottom: 0 !important;
}
py-footer-navigation .middle-right-col .link-item-wrapper,
py-footer-navigation .right-col .link-item-wrapper {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
py-footer-navigation .footer-promise-banner {
  border-bottom: 1px solid var(--py-color-grey-700) !important;
}

py-header-navigation-item {
  border-radius: 4px !important;
}
py-header-navigation-item:hover {
  background-color: var(--py-color-grey-100) !important;
  text-decoration: underline;
}
py-header-navigation-item.active {
  background-color: var(--py-color-grey-200) !important;
}

py-detailed-stock-info .basic-info-wrapper span.link-text,
py-detailed-stock-info .extended-stock-info-button span.link-text {
  color: var(--py-color-button-primary) !important;
}
py-detailed-stock-info .basic-info-wrapper i,
py-detailed-stock-info .extended-stock-info-button i {
  color: var(--py-color-button-primary) !important;
}
py-detailed-stock-info .basic-info-wrapper:hover,
py-detailed-stock-info .extended-stock-info-button:hover {
  text-decoration-color: var(--py-color-button-primary) !important;
}

a,
.link,
.btn.btn-link {
  color: var(--py-color-button-primary) !important;
}
a:hover,
.link:hover,
.btn.btn-link:hover {
  color: var(--py-color-button-primary) !important;
}
a:disabled,
.link:disabled,
.btn.btn-link:disabled {
  color: var(--py-color-grey-dark) !important;
}
a:visited,
.link:visited,
.btn.btn-link:visited {
  color: var(--py-color-button-primary) !important;
}
a:focus-visible:link, a:focus-visible:visited,
.link:focus-visible:link,
.link:focus-visible:visited,
.btn.btn-link:focus-visible:link,
.btn.btn-link:focus-visible:visited {
  outline: 1px auto var(--py-color-button-primary--60) !important;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
py-back-to-top > div {
  background-color: var(--py-color-primary-500) !important;
}
py-back-to-top > div:hover {
  background-color: var(--py-color-primary-700) !important;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
cx-page-slot.FooterLogo,
[cx-page-slot].FooterLogo {
  flex-direction: row !important;
}
cx-page-slot.FooterLogo::before,
[cx-page-slot].FooterLogo::before {
  content: none !important;
}
cx-page-slot.FooterLogo a,
[cx-page-slot].FooterLogo a {
  color: var(--py-color-primary-400) !important;
}
cx-page-slot.FooterLogo a i,
[cx-page-slot].FooterLogo a i {
  color: var(--py-color-white) !important;
}
cx-page-slot.FooterLogo py-banner,
[cx-page-slot].FooterLogo py-banner {
  flex: none !important;
}
cx-page-slot.FooterLogo py-banner.site-logo,
[cx-page-slot].FooterLogo py-banner.site-logo {
  margin-top: 1.25rem;
  margin-left: 0;
}
@media (min-width: 768px) {
  cx-page-slot.FooterLogo py-banner.site-logo,
  [cx-page-slot].FooterLogo py-banner.site-logo {
    margin-top: 0;
    margin-left: 1.25rem;
  }
}
cx-page-slot.FooterLogo py-banner.site-logo [pyMedia],
[cx-page-slot].FooterLogo py-banner.site-logo [pyMedia] {
  max-width: 140px !important;
}
cx-page-slot.FooterLogo py-paragraph,
[cx-page-slot].FooterLogo py-paragraph {
  flex: none !important;
}
@media (max-width: 991.98px) {
  cx-page-slot.FooterLogo py-paragraph,
  [cx-page-slot].FooterLogo py-paragraph {
    display: initial !important;
  }
}
cx-page-slot.FooterLogo py-paragraph .paragraph-holder,
[cx-page-slot].FooterLogo py-paragraph .paragraph-holder {
  font-size: 16px;
}
cx-page-slot.FooterLogo py-paragraph .paragraph-holder div,
[cx-page-slot].FooterLogo py-paragraph .paragraph-holder div {
  color: var(--py-color-grey-200) !important;
}
cx-page-slot.FooterLogo py-paragraph .paragraph-holder div a:hover,
[cx-page-slot].FooterLogo py-paragraph .paragraph-holder div a:hover {
  color: var(--py-color-info-200) !important;
}
@media (max-width: 991.98px) {
  cx-page-slot.FooterLogo,
  [cx-page-slot].FooterLogo {
    height: auto !important;
    flex-direction: column !important;
    padding-bottom: 50px;
  }
  cx-page-slot.FooterLogo > :first-child,
  cx-page-slot.FooterLogo > :last-child,
  [cx-page-slot].FooterLogo > :first-child,
  [cx-page-slot].FooterLogo > :last-child {
    margin-left: initial;
    margin-right: initial;
  }
}
@media print {
  cx-page-slot.FooterLogo,
  [cx-page-slot].FooterLogo {
    page-break-before: always !important;
  }
  cx-page-slot.FooterLogo py-link-collection,
  cx-page-slot.FooterLogo py-country-selector,
  [cx-page-slot].FooterLogo py-link-collection,
  [cx-page-slot].FooterLogo py-country-selector {
    display: none !important;
  }
}
cx-page-slot.Footer .extended-footer-wrapper,
[cx-page-slot].Footer .extended-footer-wrapper {
  flex-direction: column !important;
  align-items: center;
  border-top: 1px solid var(--py-color-grey-700) !important;
  border-bottom: 1px solid var(--py-color-grey-700) !important;
}
@media (min-width: 768px) {
  cx-page-slot.Footer .extended-footer-wrapper,
  [cx-page-slot].Footer .extended-footer-wrapper {
    padding: 20px;
  }
  cx-page-slot.Footer .extended-footer-wrapper > div,
  [cx-page-slot].Footer .extended-footer-wrapper > div {
    justify-content: center !important;
  }
}
cx-page-slot.Footer .extended-footer-wrapper py-country-selector,
[cx-page-slot].Footer .extended-footer-wrapper py-country-selector {
  display: none !important;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
cx-navigation .navigation-header {
  font-size: 18px !important;
  color: var(--py-color-grey-200) !important;
  margin-bottom: 1rem !important;
}
cx-navigation .navigation-body .has-content,
cx-navigation .navigation-body-horizontal:not(.certificate-link) .has-content {
  padding-bottom: 16px;
}
cx-navigation .navigation-body-horizontal.certificate-link .link-item-wrapper {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  cx-navigation .navigation-body-horizontal .has-content {
    padding-bottom: 0;
  }
}
cx-navigation .navigation-body .no-link,
cx-navigation .navigation-body-horizontal .no-link {
  color: var(--py-color-white) !important;
}
cx-navigation .navigation-body a.open-consent-link,
cx-navigation .navigation-body cx-generic-link a,
cx-navigation .navigation-body-horizontal a.open-consent-link,
cx-navigation .navigation-body-horizontal cx-generic-link a {
  display: flex;
  color: var(--py-color-primary-300) !important;
}
cx-navigation .navigation-body a.open-consent-link:focus, cx-navigation .navigation-body a.open-consent-link:visited, cx-navigation .navigation-body a.open-consent-link:active,
cx-navigation .navigation-body cx-generic-link a:focus,
cx-navigation .navigation-body cx-generic-link a:visited,
cx-navigation .navigation-body cx-generic-link a:active,
cx-navigation .navigation-body-horizontal a.open-consent-link:focus,
cx-navigation .navigation-body-horizontal a.open-consent-link:visited,
cx-navigation .navigation-body-horizontal a.open-consent-link:active,
cx-navigation .navigation-body-horizontal cx-generic-link a:focus,
cx-navigation .navigation-body-horizontal cx-generic-link a:visited,
cx-navigation .navigation-body-horizontal cx-generic-link a:active {
  color: var(--py-color-primary-300) !important;
}
cx-navigation .navigation-body a.open-consent-link:hover,
cx-navigation .navigation-body cx-generic-link a:hover,
cx-navigation .navigation-body-horizontal a.open-consent-link:hover,
cx-navigation .navigation-body-horizontal cx-generic-link a:hover {
  color: var(--py-color-white) !important;
}
cx-navigation .navigation-body a.open-consent-link:hover i,
cx-navigation .navigation-body cx-generic-link a:hover i,
cx-navigation .navigation-body-horizontal a.open-consent-link:hover i,
cx-navigation .navigation-body-horizontal cx-generic-link a:hover i {
  color: var(--py-color-white) !important;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  border-color: var(--py-color-grey-900) !important;
}

:root {
  --py-font-primary: primary, primary-fallback-0, primary-fallback-1, sans-serif;
  --py-font-primary-light: primary-light, primary-light-fallback-0, primary-light-fallback-1, sans-serif;
  --py-font-secondary: secondary, secondary-fallback-0, secondary-fallback-1, sans-serif;
  --py-font-secondary-bold: secondary-bold, secondary-bold-fallback-0, secondary-bold-fallback-1, sans-serif;
}
:root .py-font-primary {
  font-family: var(--py-font-primary);
}
:root .py-font-primary-light {
  font-family: var(--py-font-primary-light);
}
:root .py-font-secondary {
  font-family: var(--py-font-secondary);
}
:root .py-font-secondary-bold {
  font-family: var(--py-font-secondary-bold);
}

cx-page-layout.LogoutPageTemplate,
cx-page-layout.LoginPageTemplate,
cx-page-layout.PasswordResetPageTemplate {
  background-image: url("../assets-velimark/images/hero-banner.jpg");
  background-image: -webkit-image-set(url("../assets-velimark/images/hero-banner.webp") 1x);
}

cx-page-layout.RegistrationPageTemplate,
cx-page-layout.CheckoutPageTemplate,
cx-page-layout.OrderConfirmationPageTemplate {
  background-image: url("../assets-velimark/images/dot-pattern-1.jpg");
  background-image: -webkit-image-set(url("../assets-velimark/images/dot-pattern-1.webp") 1x);
  background-repeat: repeat;
  background-size: auto;
}

py-storefront.BrandLandingPageTemplate main {
  background-image: url("../assets-velimark/images/brand-landing-page-background.jpg");
  background-image: -webkit-image-set(url("../assets-velimark/images/brand-landing-page-background.webp") 1x);
  background-position: top right;
  background-size: cover;
}

py-storefront.ErrorPageTemplate main {
  background-image: url("../assets-velimark/images/error-not-found-background.jpg");
  background-image: -webkit-image-set(url("../assets-velimark/images/error-not-found-background.webp") 1x);
  background-position: center;
  background-size: cover;
  z-index: -1;
}

@media (max-width: 767.98px) {
  cx-page-layout.LandingPageTemplate .StartCategoryPuffs .btn-ternary {
    width: 100% !important;
    font-size: 18px !important;
  }
  cx-page-layout.LandingPageTemplate .StartCategoryPuffs py-paragraph * {
    text-align: left !important;
  }
}

.flag-sprite {
  width: 32px;
  scale: 0.8;
}

.flag-sprite-Belgium {
  background: url(/assets-velimark/images/flags/belgium.svg) no-repeat;
}

.flag-sprite-Czech-Republic {
  background: url(/assets-velimark/images/flags/czech-republic.svg) no-repeat;
}

.flag-sprite-Denmark {
  background: url(/assets-velimark/images/flags/denmark.svg) no-repeat;
}

.flag-sprite-Estonia {
  background: url(/assets-velimark/images/flags/estonia.svg) no-repeat;
}

.flag-sprite-Finland {
  background: url(/assets-velimark/images/flags/finland.svg) no-repeat;
}

.flag-sprite-Hungary {
  background: url(/assets-velimark/images/flags/hungary.svg) no-repeat;
}

.flag-sprite-Latvia {
  background: url(/assets-velimark/images/flags/latvia.svg) no-repeat;
}

.flag-sprite-Lithuania {
  background: url(/assets-velimark/images/flags/lithuania.svg) no-repeat;
}

.flag-sprite-Luxembourg {
  background: url(/assets-velimark/images/flags/luxembourg.svg) no-repeat;
}

.flag-sprite-Netherlands {
  background: url(/assets-velimark/images/flags/netherlands.svg) no-repeat;
}

.flag-sprite-Norway {
  background: url(/assets-velimark/images/flags/norway.svg) no-repeat;
}

.flag-sprite-Poland {
  background: url(/assets-velimark/images/flags/poland.svg) no-repeat;
}

.flag-sprite-Romania {
  background: url(/assets-velimark/images/flags/romania.svg) no-repeat;
}

.flag-sprite-Sweden {
  background: url(/assets-velimark/images/flags/sweden.svg) no-repeat;
}

.flag-sprite-Switzerland {
  background: url(/assets-velimark/images/flags/switzerland.svg) no-repeat;
}